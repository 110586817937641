import { default as abonnement_45geactiveerdAhy2NeF8C0Meta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/abonnement-geactiveerd.vue?macro=true";
import { default as abonnement_45geannuleerdkf9eiigdkfMeta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/abonnement-geannuleerd.vue?macro=true";
import { default as abonnement_45samenstellensbwtZA1Rf4Meta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/abonnement-samenstellen.vue?macro=true";
import { default as abonnementaULNa5QsFEMeta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/abonnement.vue?macro=true";
import { default as abonnementwTMCE5yIC6Meta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/account/abonnement.vue?macro=true";
import { default as adreszfIVwf4ccaMeta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/account/adres.vue?macro=true";
import { default as gegevensUvG4fJQu21Meta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/account/gegevens.vue?macro=true";
import { default as indexJOQlmjKj7JMeta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/account/index.vue?macro=true";
import { default as resetwachtwoordvMvbYq6zZfMeta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/account/resetwachtwoord.vue?macro=true";
import { default as wachtwoord_45wijzigenTcMLGq8d7kMeta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/account/wachtwoord-wijzigen.vue?macro=true";
import { default as activeren5yF7PAlj7IMeta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/activeren.vue?macro=true";
import { default as afrekenenQjbQkYOLQ5Meta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/afrekenen.vue?macro=true";
import { default as algemenevoorwaardenoTAecbiGglMeta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/algemenevoorwaarden.vue?macro=true";
import { default as contactoCXzp7DiyFMeta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/contact.vue?macro=true";
import { default as indexE0HoLazFYtMeta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/index.vue?macro=true";
import { default as inloggenGKFCiPS3wkMeta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/inloggen.vue?macro=true";
import { default as overons3WPd23UuqiMeta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/overons.vue?macro=true";
import { default as registrerenjXuknQXQj9Meta } from "/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/registreren.vue?macro=true";
export default [
  {
    name: "abonnement-geactiveerd",
    path: "/abonnement-geactiveerd",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/abonnement-geactiveerd.vue").then(m => m.default || m)
  },
  {
    name: "abonnement-geannuleerd",
    path: "/abonnement-geannuleerd",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/abonnement-geannuleerd.vue").then(m => m.default || m)
  },
  {
    name: "abonnement-samenstellen",
    path: "/abonnement-samenstellen",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/abonnement-samenstellen.vue").then(m => m.default || m)
  },
  {
    name: "abonnement",
    path: "/abonnement",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/abonnement.vue").then(m => m.default || m)
  },
  {
    name: "account-abonnement",
    path: "/account/abonnement",
    meta: abonnementwTMCE5yIC6Meta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/account/abonnement.vue").then(m => m.default || m)
  },
  {
    name: "account-adres",
    path: "/account/adres",
    meta: adreszfIVwf4ccaMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/account/adres.vue").then(m => m.default || m)
  },
  {
    name: "account-gegevens",
    path: "/account/gegevens",
    meta: gegevensUvG4fJQu21Meta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/account/gegevens.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexJOQlmjKj7JMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-resetwachtwoord",
    path: "/account/resetwachtwoord",
    meta: resetwachtwoordvMvbYq6zZfMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/account/resetwachtwoord.vue").then(m => m.default || m)
  },
  {
    name: "account-wachtwoord-wijzigen",
    path: "/account/wachtwoord-wijzigen",
    meta: wachtwoord_45wijzigenTcMLGq8d7kMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/account/wachtwoord-wijzigen.vue").then(m => m.default || m)
  },
  {
    name: "activeren",
    path: "/activeren",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/activeren.vue").then(m => m.default || m)
  },
  {
    name: "afrekenen",
    path: "/afrekenen",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/afrekenen.vue").then(m => m.default || m)
  },
  {
    name: "algemenevoorwaarden",
    path: "/algemenevoorwaarden",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/algemenevoorwaarden.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inloggen",
    path: "/inloggen",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/inloggen.vue").then(m => m.default || m)
  },
  {
    name: "overons",
    path: "/overons",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/overons.vue").then(m => m.default || m)
  },
  {
    name: "registreren",
    path: "/registreren",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20241204081107733/pages/registreren.vue").then(m => m.default || m)
  }
]