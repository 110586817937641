<template>
  <NuxtLayout :name="layout">
    <NuxtLoadingIndicator color="repeating-linear-gradient(to right,#c5e0cc 0%,#2b5337 100%)"/>
    <NuxtPage/>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { showError } from '#app';
const route = useRoute();

if ('setup' in route.query) {
  throw new Error('error in setup')
}
if ('mounted' in route.query) {
  onMounted(() => {
    throw new Error('error in mounted')
  })
}
function triggerError () {
  throw new Error('manually triggered error')
}

const layout = ref('')
//if date is 13 november, set layout to 'offlinemessage'
// if(new Date().getDate() === 18 && new Date().getMonth() === 10 && new Date().getFullYear() === 2024){
//   layout.value = 'offlinemessage'
// } else
  if(route.path === '/afrekenen'){
  layout.value = 'afrekenen'
  reloadNuxtApp({
    path: "/afrekenen",
    ttl: 1000, // default 10000
  });
} else {
  if (new Date().getDay() === 0) {
    layout.value = 'zondag'
  } else {
    layout.value = 'default'
  }
}
</script>